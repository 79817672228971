<template>
  <div class="container-fluid mt--6">
    <div class="card mb-3">
      <div class="card-body">
        <div class="row justify-content-md-center">
          <div class="col-sm-12 text-center">
            <el-breadcrumb separator-class="el-icon-arrow-right">
              <el-breadcrumb-item :to="{ path: '/standing-order' }">Standing Order</el-breadcrumb-item>
              <el-breadcrumb-item>Add Standing Order Record</el-breadcrumb-item>
            </el-breadcrumb>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-xl-9 col-md-12">
        <div class="card mb-3">
          <div class="card-header">
            <div class="row align-items-center">
              <div class="col">
                <h3 class="mb-0">Add Standing Orders Record</h3>
              </div>
            </div>
          </div>

          <div class="card-body" v-loading="loading">
            <el-form ref="formStandingOrder" :model="form" :rules="formRule" :label-position="labelPosition" size="medium" label-width="180px">

              <el-form-item label="BankholderID" prop="bankholderid">
                <el-select v-model="form.bankholderid" @change="getPayee" placeholder="BankholderID" class="form-control-alternative">
                  <el-option v-for="item of bankAccount" :key="item.uid" :label="`${item.currency} (${item.cardholderid})`" :value="item.cardholderid"></el-option>
                </el-select>
                <div class="text-xs pl-3 mt-1" v-loading="loadingBalance">{{ balance }}</div>
              </el-form-item>

              <el-form-item label="Payee" prop="payeeid">
                <el-select v-model="form.payeeid" @change="selectPayee" placeholder="Select Payee" class="form-control-alternative">
                  <el-option v-for="payee of payeeList" :key="payee.uid" :label="`${payee.name} [${payee.currency}]`" :value="payee.uid"></el-option>
                </el-select>
              </el-form-item>
              
              <el-form-item label="Payee Name">
                <el-input v-model="payeeInfo.name" readonly class="form-control-alternative"></el-input>
              </el-form-item>

              <el-form-item :label="form.currency == 'GBP' ? 'Account Number' : 'IBAN'">
                <el-input v-model="payeeInfo.accountnumber" readonly class="form-control-alternative"></el-input>
              </el-form-item>
              
              <el-form-item :label="form.currency == 'GBP' ? 'Sort Code' : 'BIC'">
                <el-input v-model="payeeInfo.sortcode" readonly class="form-control-alternative"></el-input>
              </el-form-item>

              <el-form-item label="Amount" prop="amount">
                <el-input type="number" v-model="form.amount" placeholder="Amount" class="form-control-alternative"></el-input>
              </el-form-item>

              <el-form-item label="Reference" prop="reference">
                <el-input v-model="form.reference" placeholder="Reference" v-input-filter class="form-control-alternative"></el-input>
              </el-form-item>

              <el-form-item label="Type of payment" prop="type">
                <el-select v-model="form.type" placeholder="Type of payment" class="form-control-alternative">
                  <el-option label="Repeat until a date" value="1"></el-option>
                  <el-option label="Repeat for a number of payment" value="2"></el-option>
                  <el-option label="Repeat until cancelled" value="3"></el-option>
                </el-select>
              </el-form-item>

              <el-form-item label="Start Date" prop="startdate" v-if="form.type">
                <el-date-picker v-model="form.startdate" :picker-options="datePickerOptions" type="date" placeholder="Select Start Date" class="form-control-alternative"></el-date-picker>
              </el-form-item>


              <el-form-item label="Frequency" prop="frequency" v-if="form.type">
                <el-select v-model="form.frequency" placeholder="Frequency" class="form-control-alternative">
                  <el-option label="Daily" value="daily"></el-option>                  
                  <el-option label="Monthly" value="monthly"></el-option>                  
                  <el-option label="Yearly" value="yearly"></el-option>                  
                </el-select>
              </el-form-item>

              <el-form-item label="End Date" prop="enddate" v-if="form.type==='1'">
                <el-date-picker v-model="form.enddate" type="date"  :picker-options="datePickerOptions"  placeholder="Select End Date" class="form-control-alternative"></el-date-picker>
              </el-form-item>

              <el-form-item label="Number of payment" prop="count"  v-if="form.type==='2'">
                <el-input type="number" v-model="form.count" placeholder="Number of payment" class="form-control-alternative"></el-input>
              </el-form-item>
              
              <el-form-item>
                <el-button @click="$router.push('/standing-order')">Cancel</el-button>
                <el-button type="primary" @click="onSubmit()" v-loading="submiting" :disabled="submiting">Confirm</el-button>
              </el-form-item>
            </el-form>
          </div>
        </div>
      </div>

      <div class="col-xl-3 col-md-12">
        <div class="card mb-3">
          <div class="card-body">
            <a href="#" class="btn btn-primary btn-block">Make Standing Order</a>
          </div>
        </div>
      </div>
    </div>

    <PinDialog ref="pincodedialog"  @checked="pincodeChecked"/>
  </div>
</template>

<script>
import Utils from '@/utils'
import BankApi from '@/api/bankaccount'
import PayeeApi from '@/api/payee'
import StandingOrderApi from '@/api/standingorder'
import { isMobile } from "@/utils/helpers";
import PinDialog from "@/components/PinCode/PinDialog";
export default {
  components: {
    PinDialog
  },
  computed:{
    labelPosition() {
      return isMobile() ? "top" : "left";
    },
    isMobile() {
      return isMobile();
    },
  },
  data() {
    return {
      loading: false,
      submiting: false,
      isPincode: false,
      bankAccount: [],
      payeeList: [],
      loadingBalance: false,
      balance: "",
      payeeInfo: {
        name: "-",
        accountnumber: "-",
        sortcode: "-"
      },
      form: {
        payeeid: "",
        amount: "",
        bankholderid: "",
        frequency: "",
        startdate: "",
        enddate: "",
        type: "",
        count: "",
        reference: "",
      },
      formRule: {
        payeeid: [{required: true, message: "Please select Payee", trigger: "blur"}],
        amount: [{required: true, message: "Please enter Amount", trigger: "blur"}],
        bankholderid: [{required: true, message: "Please select BankholderID", trigger: "blur"}],
        frequency: [{required: true, message: "Please select Frequency", trigger: "blur"}],
        startdate: [{required: true, message: "Please select Start Date", trigger: "blur"}],
        enddate: [{required: true, message: "Please select End Date", trigger: "blur"}],
        type: [{required: true, message: "Please select Type of Payment", trigger: "blur"}],
        count: [{required: true, message: "Please enter Number of Payment", trigger: "blur"}]
      },
      datePickerOptions: {
        disabledDate (date) {
          return date < new Date()
        }
      }
    };
  },
  methods: {
    async getBankAccount(){
      this.loading = true
      const bankaccount = await Utils.getBankAccount()
      bankaccount.forEach(element => {
        if(['GBP', 'EUR'].includes(element.currency.toUpperCase())){
          this.bankAccount.push(element)
        }
      });
      this.loading = false
    },
    getBalance(bankholderid){
      this.balance = ""
      this.loadingBalance = true
      this.form.payeeid = ""
      this.payeeInfo.name = ""
      this.payeeInfo.accountnumber = ""
      this.payeeInfo.sortcode = ""
      BankApi.getAccountBalance(bankholderid).then(({result, data})=>{
        this.loadingBalance = false
        if(result){
          this.balance = `Balance : ${data.balance}`
        }
      })
    },
    getPayee(bankholderid){
      this.loading = true
      this.payeeList = []
      const result = Utils.findObject(this.bankAccount, bankholderid, 'cardholderid')
      this.getBalance(bankholderid)
      PayeeApi.getByCurrency(result.currency).then(({result, data})=>{
        this.loading = false
        if(result){
          this.payeeList = data
        }
      })
    },
    selectPayee(uid){
      const payee = Utils.findObject(this.payeeList, uid, 'uid')
      this.payeeInfo.name = payee.name
      this.payeeInfo.accountnumber = payee.accountnumber
      this.payeeInfo.sortcode = payee.sortcode
    },
    pincodeChecked(pincode){
      this.isPincode = pincode
      if(!pincode){
        this.submiting = false
      }else{
        this.onSubmit()
      }
    },
    onSubmit() {
      this.$refs.formStandingOrder.validate(valid=>{
        if(valid){
          this.submiting = true
          if(!this.isPincode && this.isMobile){
            this.$refs.pincodedialog.show('standing-order')
            return false;
          }
          this.form.startdate = Utils.dateFormat(this.form.startdate)
          this.form.enddate = Utils.dateFormat(this.form.enddate)
          StandingOrderApi.addRecord(this.form).then(({result, message})=>{
            this.submiting = false
            if(result){
              this.$swal(`Message`, `Add Standing Order is success`, 'success').then(()=>{
                this.$router.push('/standing-order')
              })
            }else{
              this.$swal('Message', message, 'error')
            }
          })
        }
      })

    },
  },
  async created(){
    await this.getBankAccount()
  }
};
</script>

<style>
</style>